#pdp-gallery-modal {
  .modal-container {
    height: 100%;
  }

  .modal-content {
    height: 100%;
  }

  .icon--close {
    background: none;
  }

  .modal-close {
    width: 4rem;
    height: 4rem;
    top: 2.4rem;
    right: 2.4rem;
    z-index: 2;
    @include rounded-box(5rem);

    &::after {
      @include sprite('close');
      width: 10rem;
      height: 10rem;
      padding: 0.8rem;
      background-origin: content-box;
    }
  }

  .product-zoom-modal {
    padding: 0;
  }

  .swiper-wrapper {
    overflow: auto;
    cursor: grab;
  }
}

.product-gallery {
  width: 100%;
}

.zoom-image-wrapper {
  width: 100%;
  height: auto;
}