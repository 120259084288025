// COLOR SELECTOR USED IN: PDP, Tile, Reserve in boutique

.selector-color-link {
  position: relative;
  width: 100%;
  color: $black;
  height: 4.2rem;
  display: flex;
  align-items: center;

  .selector__menu {
    display: flex;
    align-items: center;
    position: relative;

    .label {
      @extend %primary-xs-u;
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;
    }

    &__item {
      @extend %primary-xs;
      display: flex;
      align-items: center;

      &.is-disabled {
        color: $black_secondary;
      }

      &.selected {
        .swatch {
          outline: 0.1rem solid $white;
          outline-offset: 0.3rem;
        }
      }

      .swatch {
        @include touchtarget-area();
      }

      &:focus {
        outline: none;

        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }

      &__label {
        position: absolute;
        top: 0.3rem;
        right: 0;

        transform: translateX(100%);
        color: $white;
        text-decoration: none;
        white-space: nowrap;
        padding-left: 0.2em;
      }
    }
  }
}