.product-gallery {
  width: 50%;
}

.product-images {
  img {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  
  &.container {
    overflow: auto;
    height: 100vh;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.product-gallery-with-modal {
  .product-container:first-child {
    padding-top: 0;
  }

  .product-image {
    margin-top: 0.8rem;
  }
}

.zoom-image-wrapper {
  width: 100%;
  height: auto;
  margin-bottom: 0.4rem;
}

#pdp-gallery-modal .swiper-wrapper {
  flex-direction: column;
}