.modal.sizeguide-pdp-modal {
  max-width: unset;
  max-height: 85%;
  top: unset;
  border-radius: 0;
  padding: 0;

  .modal {
    &__header {
      padding: 3.2rem 1.6rem;
      .dialog__title {
        font-size: 2.8rem;
        line-height: 3.6rem;
      }
    }
  }

  .modal {
    &__body {
      padding: 0 1.6rem 3.2rem;
      .sizeGuide {
        &Modal {
          padding-top: 1.6rem;

          &__levels {
            &.form-group.form-select {
              &:not(:last-child) {
                margin-bottom: 0.8rem;
              }
            }
          }
        }
        &Table {
          &__wrapper {
            width: calc(100% + 1.6rem);
            overflow: auto;
            padding-right: 1.6rem;

            table:not(.sizeGuideTable--international) {
              margin: 2.4rem 0 1.6rem;
            }
          }
        }

        &Accordion {
          &__header {
            padding: 1.6rem 0;

            &[aria-expanded='true'] {
              padding-bottom: 0.8rem;
            }
          }
        }

        &Measure {
          &Modal {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
          }
          &__card{
            flex-direction: column;
            
            &--adult{
              gap: 1.6rem;

              &_imageContainer {
                order: 3;
              }
            }

            &-wrapper {
              &:not(:first-child) {
                margin-top: 3.2rem;
              }
            }
            
            &--kids{
              flex-direction: column;
              &_imageContainer{
                display: flex;
                justify-content: center;
                margin-bottom: 3.2rem;
              }
            }
          }
        }
      }
    }
  }

  .wrapper-size-guide-app {
    width: 100%;
    padding: 2.4rem 1.6rem;

    .table-wrapper {
      overflow-x: auto;
    }

    .text-baby {
      .text-baby-description {
        padding-left: 0;
      }
    }
  }
}