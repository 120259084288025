// temporaneo solo per riuscire a navigare nelle vecchie category page non stilate
.main-filter-wrapper {
  margin-top: 6rem;
  margin-bottom: 2rem;
  border: 0.2rem solid red;
  width: 100%;

  .refinement-bar-wrapper {
    width: 100%;

    .refinements {
      width: 100%;
      display: flex;
    }
  }
}

.tile-body .row {
  justify-content: space-between;
}

.price {
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;

  span {
    line-height: 2rem;
  }

  del {
    color: $dark_grey;
    height: 2rem;
    line-height: 2rem;
  }

  .sales {
    display: flex;
    gap: 0.8rem;
  }

  .sales[data-saletype='sale'],
  .sales[data-saletype='promotion'],
  .sales[data-saletype='employee'],
  .sales[data-saletype='sales'] {
    display: flex;
    gap: 0.8rem;

    .value {
      color: $red;
    }
  }

  .js-discount-msg {
    padding: 0 0.4rem;
    background: $light_grey;
    border-radius: 0.4rem;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    height: 2rem;
    display: inline-flex;
    padding-bottom: 0.1rem;
    align-items: center;
  }

  .value {
    height: 2rem;
  }
}

.promo-text {
  color: $promo_pink;

  &[data-promo-type='employee'] {
    color: $dark-blue;
  }
}

#search-show,
#product-show {
  main {
    padding-top: 0;
  }
}

[data-react='catalog-app'] {
  position: relative;
}

.listing-container {
  margin-top: 4rem;

  &.loading {
    pointer-events: none;

    .product-tile__image {
      transition: initial;
    }
  }

  .wrapper-refine-button {
    margin-top: $space-unit;
  }

  .refine {
    @include animate(0.4);
    transition-property: transform, opacity;
  }

  .shelf-container {
    &:first-child {
      .refine {
        position: fixed;
      }
    }

    &:not(:first-child) {
      .refine {
        position: relative;
        top: initial;
        z-index: 1;
      }
    }
  }

  .filter-flex {
    display: flex;
  }

  .page-title {
    text-align: left;
    margin-bottom: 2rem;
  }
}

.product-grid__item {
  border: $space-line solid red;
  padding: 1rem;
  margin: 1rem;
}

.plp-seo .col {
  padding: 0;
}

.plp-seo-text strong {
  font-weight: bolder;
}

.plp-seo-text a {
  color: $blue;
  text-decoration: underline;
}

.plp-seo-text a:hover {
  text-decoration: underline;
}

.plp-seo-text h2 {
  font-size: large;
  font-weight: bolder;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.plp-seo-text h3 {
  font-size: small;
  font-weight: bolder;
  margin-top: 2rem;
}

.grid-container {
  @include reset-list;
  display: grid;
  grid-template-columns: repeat(2, minmax(.1rem, 1fr));
  column-gap: 2rem;
  row-gap: 4rem;
  grid-template-rows: auto;
  width: 100%;

  li {
    min-width: 0;
  }

  .plppromo {

    &--2x2,
    &--2x1 {
      &:focus {
        outline: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 0.2rem $outline;
          @include z;
        }
      }
    }

    &--2x2 {
      @extend .plppromo;
      grid-column: span 2;
      grid-row: span 2;
    }

    &--2x1 {
      @extend .plppromo;
      grid-column: span 2;
    }
  }

  .pages-number {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-bottom: 4rem;
    align-items: center;
    width: fit-content;

    .btn.inactive {
      opacity: 0.6;
    }
  }
}

.plp-loading,
.plp-error {
  display: flex;
  align-items: center;
  padding-top: ($space-unit * 16);
  padding-bottom: ($space-unit * 4);
  @extend %primary-s-u;
}

.plp-error {
  &::before {
    content: '';
    @include fixedSprite('alert');
    width: $space-unit * 4;
    height: $space-unit * 4;
    margin-right: $space-unit * 2;
  }
}

.plppromo {
  position: relative;

  .plppromo__link {
    display: block;
    position: relative;
    height: 100%;
  }

  .plppromo__title {
    position: absolute;
    top: $space-unit * 4;
    left: $space-unit * 4;
    text-transform: uppercase;
    @include z;

    &.white-title {
      color: $white;
    }
  }

  picture {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.shelf-container {
  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $space-unit * 3;
    padding-bottom: $space-unit * 6;
    text-decoration: initial;

    i {
      margin-top: -0.2rem;
    }
  }
}

.editmode {
  .plppromoarea {
    background-color: yellow;
  }

  .experience-region {

    &.experience-promo1,
    &.experience-promo2,
    &.experience-promo3,
    &.experience-promo4,
    &.experience-promo5,
    &.experience-promo6 {
      width: 100%;
      height: 100%;
    }
  }

  .experience-component_plppromo {
    width: 100%;
    height: 100%;
  }
}

.page-numbers-block {
  &:first-of-type {
    margin-left: 3.2rem;
  }

  &:last-of-type {
    margin-right: 3.2rem;
  }
}

a.btn-n-page,
span.btn-n-page {
  color: $dark_grey;
  text-decoration: none;
  padding: 1.2rem;
  background-color: $light_grey;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.6rem;

  font-weight: 600;
  margin: 0 0.4rem;

  &.active {
    background-color: $blue;
    color: $white;
  }

  &.last {
    opacity: 0.8;
  }
}

.btn-subcategory {
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.6rem;
  border-radius: 0.4rem;
  padding: 0.8rem 1.6rem;
  color: $dark_grey;
  background-color: $light_grey;

  &.selected {
    border: none;
    background-color: $blue;
    color: $white;
  }

  &:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.subcategories-container {
  display: flex;
  margin: 2.4rem 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;
    text-align: center;
  }

  .item-wrapper {
    flex: none;
    margin: 0;
    height: 3.2rem;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    will-change: transform;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;

    &.active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    a {
      //pointer-events: none;
      user-select: none;
      -webkit-user-drag: none;
    }

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }
}

.plp-seo {
  padding-top: 4rem;
  padding-bottom: 8rem;
  color: $dark_grey;
}

.grid-product.refinement-grid {
  margin-top: 4rem;
}

.plp-product-labels,
.plp-product-promo-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: absolute;
  top: unset;
  bottom: 0;
  padding: 0 0.8rem;
  z-index: 1;
}

.plp-product-label {
  display: inline-block;
  padding: 0.4rem 0.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6;

  font-weight: bold;
  border-radius: 0.5rem;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &-promo {
    color: $light_orange;
    background-color: $promo_pink;
  }
}

.sale-product-label {
  background-color: $red;
  color: $white;
}

.grid-1 {
  column-gap: 1.2rem;
  row-gap: 1.2rem;
}

.change-grid {
  &::before {
    content: '';
    opacity: 0.3;
  }

  &.change-grid-1x {
    &::before {
      @include fixedSprite('icon-6x');
    }
  }

  &.change-grid-2x {
    &::before {
      @include fixedSprite('icon-2x');
    }
  }

  &.selected {
    &::before {
      opacity: 1;
    }
  }
}

.searchNoResults {
  &Category {
    padding-bottom: 4.8rem;
    color: $dark_grey;
  }

  &Header {
    &__title {
      color: $blue;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 3.2rem;
    }

    &__subtitle {
      &_link {
        font-weight: 700;
        color: $blue;
      }
    }
  }
}
