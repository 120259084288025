#pdp-gallery-modal {
  .swiper-wrapper {
    overflow: auto;
    flex-direction: column;
  }
}

.product-images {
  display: flex;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    width: 90%;
    margin-right: 0.8rem;
  }
}

.product-gallery-with-modal {
  .swiper-slide {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.zoom-image-wrapper {
  width: 100%;
  height: auto;

  &:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.8rem;
  }
}