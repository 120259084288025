// SIZE SELECTOR USED IN: cart

.selector-size-inline {
  .selector__menu {
    .label {
      margin-right: $space-unit * 2;
    }
    ul {
      grid-template-columns: repeat(5, 1fr);
      row-gap: 1.6rem;
      padding-bottom: 0.8rem;
    }
    &__item {
      margin-right: 0;
    }
  }
  .selector__button {
    &::after {
      content: '';
      right: 0;
      @include fixedSprite('chevron-right');
      position: absolute;
      top: -0.1rem;
      transform: rotate(90deg);
    }
  }
}
