// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  position: relative;
  // @include border('top');
  color: $black;

  .label-color-value {
    color: $dark_grey;
  }

  .selector__label {
    display: flex;
    align-items: center;
    padding: 0;

    span {
      @extend %primary-xs-u;
      margin-right: ($space-unit * 2);
    }

    i {
      @extend %primary-xs;
    }

    .errormessage {
      display: block;
      text-transform: none;
      position: absolute;
      right: 2rem;
      color: $status_error;
    }

    .selector__button__info {
      margin-left: ($space-unit * 2);
      opacity: 0.5;
      text-transform: initial;
    }
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 0 0 1rem;
    flex-wrap: wrap;

    .color__group__wrapper {
      display: flex;
      position: relative;
      margin: 1.6rem 4.8rem 0 0;
      flex-direction: column;
    }

    span.color-section-price {
      margin-top: 0.8rem;

      &[data-salecolor=true] {
        color: $red;
      }
    }

    .label {
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;

      li:first-child {
        margin-left: 0.1rem;
      }

    }

    .color-expanded-divider {
      border-left: 0.1rem solid $medium_grey;
      position: absolute;
      height: 100%;
      right: -2.4rem;
    }

    &__item {
      width: 2rem;
      height: 2rem;
      justify-content: center;
      display: flex;
      align-items: center;

      &:hover:not(.selected) {
        .swatch {
          &::after {

            transform: translateX(-50%) scaleX(1);
          }
        }
      }

      &__label {
        @extend .visually-hidden;
      }

      .swatch {
        margin-right: 0;
        @include touchtarget-area();
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 50%;

        &::after {
          content: '';
          height: 0.1rem;
          bottom: -0.5rem;
          background-color: $grey_line;
          width: $swatch-size-desktop;
          @include center('horizontal');
          @include animate(0.3);

          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        opacity: 0.3;
      }

      &:hover {
        border: 0.1rem solid $light_grey;
        border-radius: 50%;
      }

      &:focus {
        outline: none;

        .swatch {
          @include touchtarget-focus();
        }
      }

      &.selected {
        position: relative;
        border: 0.1rem solid $blue;
        border-radius: 50%;
      }
    }
  }

  &.bottom-divider {
    @include border('bottom');
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;

    .selector__menu__item {
      color: inherit;

      &.selected {
        .swatch {
          &::after {
            background-color: $white;
          }
        }
      }

      &:focus {
        .swatch {
          @include touchtarget-focus($outline-color-inverted);
        }
      }
    }
  }

  &.intile {
    height: $selector-size-intile-height;
  }
}