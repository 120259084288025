.product-container {
  position: relative;
  z-index: 1;
  margin: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  //non mettere overflow hidden, rompe il position sticky
  // &:first-child {
  //   padding-top: $header-height-mobile;
  // }

  &.product-wrapper {
    margin: 0 4rem;
    top: 0;

    >* {
      padding-top: 2rem;
      transition: all .6s cubic-bezier(0.1, 0.3, 0.4, 1);
    }
  }

  .product-col {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.label-low-availability {
  color: $orange;
  font-weight: bold;
}

#component-product-gallery-with-modal-app {
  min-height: 55vw;
}

.product-sidebar {
  #variation-attrs {
    min-height: 23.5rem;
  }
}

.pdp-product-labels,
.pdp-product-promo-message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
  top: unset;
}

.pdp-product-promo-message {
  padding: 0.8rem 0;
}

.pdp-product-label {
  display: inline-block;
  padding: 0.4rem 0.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.6;

  font-weight: bold;
  border-radius: 0.5rem;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &-promo {
    color: $light_orange;
    background-color: $promo_pink;
  }
}

.product-detail .prices {
  width: 100%;
  min-height: 12.6rem;
}