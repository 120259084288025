.grid-container {
  column-gap: 1.6rem;

  .grid-footer {
    grid-column: span 2;
  }

  .pages-number {
    width: 100%;
    justify-content: space-between;
  }

  .page-numbers-block:last-of-type {
    margin-right: 0;
  }
  .page-numbers-block:first-of-type {
      margin-left: 0;
  }

  &.grid-1 {
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;

    .product-image:hover {
      .tile-swiper-icon {
        opacity: 1;
      }
    }
  }
}

.subcategories-container {
  justify-content: flex-start;
}

.plp-seo {
  width: 100%;
}

.listing-container .product-tile .swiper-button-container{
  display: none;
}
.listing-container {
  .grid-container {
    
    &.grid-1 {
      .editorial-grid-position {
        &--1,
        &--2,
        &--3,
        &--4 {
          display: none;
        }
      }
    }
    
    .editorial-grid-position {
      &--1 {
        grid-row-start: 3;
        grid-column: 1 / 3;
        margin: 0 -2.4rem;
      }
      &--2 {
        grid-row-start: 7;
        grid-column: 1 / 3;
        margin: 0 -2.4rem;
      }
      &--3 {
        grid-row-start: 11;
        grid-column: 1 / 3;
        margin: 0 -2.4rem;
      }
      &--4 {
        grid-row-start: 10;
        grid-column: 1 / 3;
        margin: 0 -2.4rem;
      }
    }
  }
}

.change-grid {
  &.change-grid-1x {
    &::before {
      @include fixedSprite('icon-1x');
    }
  }
    
  &.change-grid-2x {
    &::before {
      @include fixedSprite('icon-2x');
    }
  }
}

.searchNoResults{
  margin-bottom: 8rem;

  &Category{
    padding-bottom: 4rem;
  }
}