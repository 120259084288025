.sticky-tray {
  opacity: 0;
  transition-duration: 0.3s;
  width: unset;

  //   -webkit-transform: translateY(-100%);
  &::before {
    content: initial;
  }

  &__name-price {
    width: 28.5rem;
    // justify-content: flex-end;
    font-size: font-property($font-desktop, 'xs', 'font-size');
    line-height: font-property($font-desktop, 'xs', 'line-height');

  }

  // &__price {
  //   margin-left: ($space-unit * 4);
  //   margin-right: 3rem;
  // }
  &__button {
    button.button {
      padding: 0;

      span {
        font-size: font-property($font-desktop, 'xs', 'font-size');
        line-height: font-property($font-desktop, 'xs', 'line-height');

      }
    }
  }

  &__modal.modal {
    width: calc(50% + var(--scrollbar-width) / 2);
    top: $header-height-desktop + 4.6rem;
    left: initial;
    bottom: initial;
    padding: 0.8rem 2.8rem 2rem 2.8rem;

    &.ReactModal__Content {

      transform: translateY(-3.2rem);
    }

    &.ReactModal__Content--after-open {

      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {

      transform: translateY(-3.2rem);
    }

    .modal__close {
      padding: 2.2rem;
    }

    .selector-size-expanded .selector__menu ul {
      min-width: initial;
    }
  }

  &__overlay {
    &.overlay-modal {
      @include z;
    }
  }
}