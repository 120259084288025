// SIZE SELECTOR USED IN: PDP, PLP Tile, Reserve in boutique, Shop by Look

.selector-size-expanded {
  position: relative;
  color: $black;
  z-index: 1;
  background: white;

  .recommended {
    flex: 1;
    color: $black_secondary;
    margin-left: ($space-unit * 4);

    span:only-of-type {
      display: none;
    }
  }

  .selector__header {
    display: flex;
    align-items: center;
    padding: 1.1rem 0 0;
    justify-content: space-between;
    white-space: pre;
  }

  .selector__label {
    margin-right: ($space-unit * 4);

    span {
      @extend %primary-xs-u;
    }

    i {
      margin-left: ($space-unit * 4);
      @extend %primary-xs;
    }
  }

  .label-low-availability {
    color: $orange;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

    text-decoration: none;
  }

  .label-soldout-product {    
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
    text-decoration: none; 

    &--soldout{
      color: $red;
    }
    &--dash{
      color: $dark_grey;
    }

    &--similarLink{
      color: $blue;
      text-decoration: underline;
    }
  }

  .errormessage {
    color: $status_error;
    margin-right: ($space-unit * 2);
    @include text-shorten;
  }

  .selector__menu {
    display: flex;
    align-items: center;
    padding: 1.4rem 0 1.7rem;

    .size-selector-label-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 0.4rem;
      border: 0.2rem solid $medium_grey;
      cursor: pointer;
      padding-right: 0.4rem;

      transition: all 0.4s ease;

      li[disabled] {
        cursor: default;
      }

      .size-default-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
        border-bottom: 0.1rem solid $light_grey;

      }

      ul {
        height: auto;
        overflow: auto;
        flex-wrap: nowrap;

        transition: all 0.4s ease;

        &::-webkit-scrollbar {
          width: 0.4rem;
          border-radius: 1rem;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $medium_grey;
          border-radius: 2rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $dark_grey;
        }
      }

      li {
        border-bottom: 0.1rem solid $light_grey;

        &:is([disabled]) {
          opacity: 0.3;
        }

        &:hover {
          background-color: $light_grey;
          border-radius: 0.4rem;
        }
      }

      p {
        color: $dark_grey;
      }
    }

    .size-selector-label-wrapper[aria-expanded="true"] {
      border-color: $blue;
      max-height: auto;

      ul {
        max-height: 25.5rem;
      }

      .size-default-wrapper p {
        color: $black;
      }

      .button--chevron-down-gray:after {
        transform: rotatez(180deg);
        margin: 0;

        transition: all 0.4s ease;
      }
    }

    .size-selector-label-wrapper[aria-expanded="false"] {

      .size-default-wrapper {
        border-bottom: 0.1rem solid transparent;
      }

      ul {
        max-height: 0;
        overflow: hidden;
      }

      .button--chevron-down-gray:after {
        margin: 0;
        transform: rotatez(0);

        transition: all 0.4s ease;
      }
    }

    .label {
      @extend .visually-hidden;
    }

    ul {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      bottom: initial;
      min-width: 100%;
      grid-template-columns: repeat($selector-size-inpage-cols-desktop, 1fr);
      column-gap: 0.4rem;
      flex-direction: column;
    }

    &__item {

      transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 1.5rem;
      width: 100%;

      &.notavailable {
        color: $black_inactive;
      }

      &.is-disabled+.selector__menu__item__label {
        color: $black_inactive;
      }

      &.notavailable+.selector__menu__item__label {
        color: $black_inactive;
      }

      &:not(.is-disabled, .notavailable)+.selector__menu__item__label {
        color: $black;
      }

      &__label {
        @extend %primary-s-u;
        position: relative;
        display: inline-block;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black;

        &::after {
          content: '';
          height: 0.1rem;
          bottom: 0;
          background-color: $grey_line;
          width: 100%;
          min-width: 1.6rem;
          @include center('horizontal');
          @include animate(0.3);

          transform: translateX(-50%) scaleX(0);
        }
      }

      &.is-disabled {
        color: $black_inactive;
        position: relative;
      }

      &.selected {
        background-color: $light_grey;
        border-radius: 0.4rem;
      }

      @include touchtarget-area();

      &:focus {
        outline: none;

        @include touchtarget-focus();
      }
    }
  }

  //INVERTED VARIANT

  &.inverted {
    border-color: $white_inactive;
    color: $white;
    background-color: $black;

    .recommended {
      color: $white_secondary;
    }

    .button--link {
      color: $white;

      &::after {
        filter: invert(1);
      }
    }

    .selector__menu__item {
      color: $white;

      &:focus {
        @include touchtarget-focus($outline-color-inverted);
      }

      &.is-disabled {
        color: $white;

        &::after {
          background-color: $white;
        }
      }

      &.selected {
        .selector__menu__item__label {
          position: relative;

          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  //INTILE VARIANT
  &.intile {
    .selector__menu {
      padding: $selector-size-intile-padding;

      ul {
        row-gap: $selector-size-intile-rowgap;
        grid-template-columns: repeat($selector-size-intile-cols, 1fr);
      }
    }
  }
}


.sizevariation-modal .modal__body .selector-size-expanded .selector__menu .size-selector-label-wrapper[aria-expanded=false] ul {
  overflow: auto;
}


body#search-show{
  .selector-size-expanded{
    .label-soldout-product{
      &--similarLink{
        display: none;
      }

      &--dash{
        display: none;
      }
    }
  }
}