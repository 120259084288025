.product-selection {
  // padding-top: ($space-unit * 20);

  .product-selection__actions-shipping {
    .button {
      height: auto;
    }
  }

  .product-selection__modelinfo {
    position: absolute;
    bottom: $space-unit * 8;
    margin-bottom: 0;
  }
}
