.selector-size-expanded {
  .recommended {
    margin-left: 0;
  }
}

.selector-size-expanded .selector__menu__item{
  padding: 1.5rem 1.2rem;
}

.quickview-content .selector-size-expanded .selector__menu .size-selector-label-wrapper[aria-expanded=false] ul {
  max-height: calc(70vh - 16.1rem);
  overflow-y: scroll;
}

.quickview-content .selector-size-expanded .selector__menu .size-selector-label-wrapper li{
  max-width: 92vw;
}