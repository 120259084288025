.listing-container {
  &.is-shelf {
    padding-top: $header-height-desktop + 10rem - 0.1rem;
  }

  .product-list {
    .product-tile {
      flex-basis: 25%;
    }
  }
}

.grid-container {
  grid-template-columns: repeat(6, 1fr);

  &.grid-2 {
    grid-template-columns: repeat(2, 50%);
  }

  &.grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &.grid-1 {
    .product-tile {
      grid-column: span 1;

      .tile-body,
      .variation-color-plp,
      .cta-show-colors {
        display: none;
      }

      .product-tile:hover {
        .tile-swiper-icon {
          opacity: 0;
          /*
          &.swiper-button-disabled {
            opacity: 0.5;
          }*/
        }
      }
    }

    .editorial-grid-position {

      &--1,
      &--2,
      &--3,
      &--4 {
        display: none;
      }
    }
  }

  .editorial-grid-position {
    &--1 {
      grid-row-start: 2;
      grid-column: 3 / 5;
    }

    &--2 {
      grid-row-start: 4;
      grid-column: 5 / 7;
    }

    &--3 {
      grid-row-start: 7;
      grid-column: 1 / 3;
    }

    &--4 {
      grid-row-start: 6;
      grid-column: 3 / 5;
    }
  }

  .product-tile {
    grid-column: span 2;
    /*&:nth-child(5n), &:nth-child(5n + 4)  {
      grid-column: span 3;
    }*/
  }

  .grid-footer {
    grid-column: span 6;
  }

  .plppromo {
    .plppromo__title {
      top: $space-unit * 8;
      left: $space-unit * 8;
    }

    &.is-double {
      picture {
        height: 100%;
      }

      img {
        position: relative;
        top: initial;
        transform: initial;
      }
    }
  }

  &.lookbook {
    li {
      &:nth-child(4n + 2) {
        .shop-by-look-module {
          left: -100%;
        }
      }

      &:nth-child(4n + 3) {
        .shop-by-look-module {
          left: -200%;
        }
      }

      &:nth-child(4n + 4) {
        .shop-by-look-module {
          left: -300%;
        }
      }
    }
  }
}

.plppromo {
  picture {
    height: calc(100% - 8.8rem);
  }

  img {
    position: absolute;
    height: auto;
    min-height: 100%;
    width: 100%;
    top: 50%;

    transform: translateY(-50%);
  }
}

.filter-tab {
  margin: 2.4rem 0;
}