.product-details {
  padding-top: ($space-unit * 8);
  padding-bottom: ($space-unit * 10);


  .made-in {
    @include reset-list;
  }

  .accordion__panel {
    line-height: 1.6rem;
    @extend %primary-xs;

    >div {
      padding-bottom: ($space-unit * 10);
    }

    &#composition-care-panel {
      img {
        width: 3rem;
        height: 3rem;
        filter: invert(100%);
        display: inline-block;
        margin: 1rem 1rem 1rem 0;
      }
    }

    .findmysizecta {
      text-decoration: underline;
      margin-top: $space-unit * 4;
    }
  }

  &__category {
    margin-bottom: 1em;
    text-transform: uppercase;
  }
}

.scroll-to-section-id.active {
  border: 0.2rem solid $blue;
  padding: 0.1rem;
}

.product-details-row {
  display: grid;
  grid-template-columns: 9.6rem calc(55% - 4.8rem) calc(45% - 4.8rem);

  .product-description {
    margin: 0 2rem;
  }

  .product-details-info {
    width: 80%;
    padding: 0;
    margin: 0 auto;

    .accordion__panel span {
      padding-bottom: 2rem;
    }

  }
}

.product-label__code {
  display: flex;
  margin-top: 2.4rem;

  span {
    margin-right: 0.8rem;
  }
}

.product-detail {
  &__name {
    font-size: 1.6rem;
    padding-right: 3rem;

    &--wrapper {
      margin-bottom: 0.8rem;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      button.cta {
        margin-left: 1rem;
        height: 3rem;
      }
    }
  }

  &__info-assets {
    .content-asset:first-child {
      margin-top: 2.4rem;
    }

    .content-asset:last-child {
      margin-bottom: 2.4rem;
    }

    p {
      margin: 1.6rem 0;
      display: flex;
      align-items: flex-start;

      &::before {
        margin-right: 0.8rem;
      }

      &.shipping {
        &::before {
          content: '';
          @include fixedSprite('pack');
        }
      }

      &.return {
        &::before {
          content: '';
          @include fixedSprite('return');
        }
      }

      &.eco {
        &::before {
          content: '';
          @include fixedSprite('eco');
        }
      }
    }
  }

  .product-gallery-button {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    padding: 2rem;
    @include rounded-box;

    &::before {
      content: '';
      @include fixedSprite('grid');
      margin-right: 1rem;
    }
  }
}

.product-detail-labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;
}

.product-label {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4;

  color: $dark_grey;

  &:not(:last-of-type) {
    margin-right: 1.6rem;
  }

  &.respect {
    color: $status_success;
  }
}

.product-info__item {
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-column-gap: 4rem;
  padding: 2rem 2rem 2rem 0;

  &:first-of-type {
    padding-top: 0;
  }

  &--label-u {
    text-transform: uppercase;
  }
}

.product-thumbnails {
  display: none;
}

.product-thumbnails-zoom {
  display: none;
  width: 6.6rem;
  height: auto;
  position: fixed;
  top: 0;
  left: 2.4rem;
  bottom: 0;
  align-items: center;
  will-change: auto;
  z-index: 10000;
  max-height: 70vh;
  margin: auto;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  gap: 0.8rem;

  &.visible {
    display: flex;
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  img, video {
    margin-bottom: 0.8rem;
    min-height: 8.8rem;
  }
}