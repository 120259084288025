.sticky-tray {
  // padding: ($space-unit * 2) 0;
  // &__name-price {
  //   margin-bottom: 0;
  //   justify-content: flex-end;
  // }
  &__modal.modal {
    // bottom: 6.4rem;
    padding: 0 2rem 1.2rem 2rem;
    .selector-color-expanded {
      border-top: initial;
      .selector__label {
        padding-top: 2rem;
      }
    }
    .selector-size-expanded .selector__menu ul {
      min-width: initial;
    }
  }
  &__price {
    flex-shrink: 0;
    // margin-left: ($space-unit * 6);
  }
}
