.product-selection {
  .product-selection__ctas {
    flex-direction: row;

    .button {
      margin: 0 $space-unit 0 0;

      &:first-child {
        margin: 0 $space-unit 0 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
