.product-detail {
  .product-added-to-bag {
    position: fixed;
    bottom: 0;
    left: 0.8rem;
    z-index: 9;
    width: calc(100% - 1.6rem);
    display: flex;
    align-items: center;
    padding: 0.8rem;

    img {
      width: 4.8rem;
      height: auto;
      margin-right: 2.4rem;
    }

    span {
      width: 40%;
    }

    a {
      margin-left: auto;
      margin-right: 0.8rem;
    }
  }

  &__info-assets {
    .content-asset:first-child {
      margin-top: 2.4rem;
    }

    .content-asset:last-child {
      margin-bottom: 2.4rem;
    }
  }

  .product-gallery-button {
    display: none;
  }
}

.product-description {
  margin-bottom: 6rem;
}

.product-details-row {
  display: flex;
  flex-direction: column;

  .product-description {
    margin: 0 0 6rem 0;
  }

  .product-details-info {
    width: 100%;
  }
}