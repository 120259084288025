// COLOR SELECTOR USED IN: PDP, Reserve in boutique

.selector-color-expanded {
  .selector__menu {
    ul {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      flex: none;
    }
  }
}
