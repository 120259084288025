.selector-color-link {
  .selector__menu {
    &:not(:hover) {
      .selector__menu__item.selected {
        .selector__menu__item__label {
          display: inline-block;
        }
      }
    }
    .focused {
      .selector__menu__item {
        &.selected {
          .selector__menu__item__label {
            display: none;
          }
        }
        &:focus {
          .selector__menu__item__label {
            display: inline-block;
          }
        }
      }
    }
  }
  .selector__menu__item {
    .selector__menu__item__label {
      display: none;
    }
    &:hover {
      .selector__menu__item__label {
        display: inline-block;
      }
    }
  }
}
