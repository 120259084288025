.modal.sizeguide-pdp-modal {
  max-width: min(65%, 92.4rem);
  max-height: min(80%, 66.4rem);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 4rem 4rem 0;

  &.ReactModal__Content {
    transform: translate(0, 10%);

    &--after-open {
      transform: translate(0);
    }

    &--before-close {
      transform: translate(0, 10%);
    }
  }

  .modal{
    &__header{
      justify-content: space-between;
    }
    &__close{
      position: relative;
      padding: 0;
    }
  }
  .dialog__title{
      font-size: 3.2rem;
      line-height: 4rem;
      color: $blue;
      padding: 0; 
  }

  .sizeGuide {
    &Modal{     
      &__wrapper{
        &_levels{
          .form-group{
            .levelCategories--disabled{
              background-color: $grey_disabled;
            }        
          }
        }
      }

      .sizeGuideAccordion__header{
        &[aria-expanded='true'] + .sizeGuideAccordion__panel{
          border-top: 0.1rem solid $light_grey;
        }
      }
    }

    &Table{
      &__wrapper{
        table,
        th,
        td {
          border: 0.1rem solid $grey_disabled;
          border-collapse: collapse;
        }

        table {
          width: 100%;
          margin: 3.2rem 0;

          thead {
            tr {
              border-radius: 0.4rem 0.4rem 0 0;

              th {
                width: 1%;
                background: $blue;
                color: $white;
                padding: 1.4rem;
                vertical-align: middle;
              }
            }
          }

          tbody {
            tr {
              border-radius: 0.4rem 0.4rem 0 0;

              td {
                background: $white;
                color: $blue;
                padding: 1.4rem;
                text-align: center;
              }
            }
          }
        }         
      }
     
    }

    &Measure{
      color: $blue;
      
      &Modal--adult{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      &__card{
        &--adult{
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          padding: 0;

          &_image{
            width: 100%;
          }
        }

        &--kids{
          display: flex;
        }
  
        &_description{
          &_title{
            text-transform: uppercase;
            order: -1;
            line-height: 2.8rem;
          }
          &_text{
            line-height: 1.6rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  
    &Accordion{
      color: $blue;
  
      &__header {
        padding: 0.8rem 0;
        color: $blue;

        &[aria-expanded='false'] + .sizeGuideAccordion__panel{
          border-bottom: 0.1rem solid $light_grey;
        }
      }
  
      &__panel {
        padding: 0;
        border-bottom: 0;

        
  
        table {
          margin: 0;
        }
      }
    }

    .text-baby {
      .text-baby-description {
        padding-left: 1.6rem;
      }

      .text-wrapper {
        .round-letter {
          background: $gray3;
          border-radius: 50%;
          width: 2rem;
          min-width: 2rem;
          font-size: 1rem;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 1.2rem;
        }

        p {
          padding-bottom: 1.6rem;
          border-bottom: 0.1rem solid $gray3;
        }
      }
    }


  }
  .wrapper-international-sizes {
    .accordion__header {
      padding: 1.4rem 1.2rem;
    }

    .accordion__panel {
      padding: 0;

      table {
        margin: 0;
      }
    }
  }
}