.product-container {
  .product-col {
    width: 50%;
    flex: 1 0 auto;
  }

  .product-col--gutter-dx {
    padding-right: ($space-unit * 7);
  }

  .product-col--gutter-sx {
    padding-left: ($space-unit * 7);
  }

  &.product-wrapper {
    .main-collapse-translate:not(.product-images) {
      top: calc(var(--nav-wrapper) + var(--ticker-height));
    }

  }
}

.product-images.container {
  padding: 0;
  scroll-behavior: smooth;
  z-index: 0;
}

.product-wrapper {
  margin-bottom: 12rem;
  display: grid;
  grid-template-columns: 9.6rem calc(55% - 4.8rem) calc(45% - 4.8rem);
}

.product-sidebar {
  height: fit-content;
  position: sticky;
  top: 2rem;
  max-width: 80%;
  padding: 0;
  margin: 0 auto;
}