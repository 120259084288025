.breadcrumb {
  display: flex;
  flex-grow: 1;
  padding-left: 0;
  flex-wrap: wrap;
  padding-top: 0;

  .plp-title {
    flex-grow: 1;
  }

  ol {
    @include reset-list;
  }

  li {
    display: inline;

    a {
      text-decoration: none;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        @include fixedSprite('chevron-right');
        top: 1.6rem;
        right: 1.6rem;
        margin: 0 ($space-unit * 2);
        vertical-align: top;
      }
    }
  }

  &__item {
    @extend %primary-s-u;
    text-decoration: none;
    display: inline-block;
  }
}

.breadcrumb-item:not(:last-child) {
  opacity: 0.8;
}