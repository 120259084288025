.product-hero {
  margin-bottom: 0;
}

.product-hero-gallery {
  width: 100%;
}

.gallery-rectangle {
  height: 0.2rem;
  background-color: $blue;

  transition: all 0.4s ease 0s;
}