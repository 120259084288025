.quickview-selectsize.modal.sizevariation-modal {
  transform: translate(0);
  height: 100%;
  max-height: 100%;
  width: 60%;
  min-width: auto;
  left: unset;
  right: 0;
  top: 0;
  border-left: 0.1rem solid $grey_disabled;
  padding: 0;
  overflow: auto;

  .modal__close {
    padding: 2.8rem 2.4rem;
  }

  .modal__body {
    width: 100%;
    margin-left: auto;
    max-height: unset;
    display: flex;

    .product-images.container {
      width: 50%;
      margin-left: 0;

      .product-container {
        margin: 0.4rem 0.8rem;
      }
    }

    .wrapper-body {
      height: 100vh;
      position: sticky;
      top: 0;
      width: 50%;
      border-left: 0.1rem solid $grey_disabled;

      .wrapper-header {
        width: 100%;
        margin-left: auto;
        padding: 2rem 2.4rem;
        border-bottom: 0.1rem solid $grey_disabled;

        .dialog__title {
          padding: 0;
        }
      }

      .quickview-product-details {
        position: relative;
        height: calc(100vh - 11.8rem);
        margin: 2.4rem;

        .sizevariation-product {
          &__content {
            margin-left: 0;

            .wrapper-breadcrumb {
              margin-bottom: 1.6rem;

              a {
                font-size: 1.2rem;

                line-height: 1.6rem;
                font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
                text-decoration: none;
                color: #666666;
              }
            }

          }

          &__name-price {
            margin-bottom: 0.8rem;
            flex-direction: column;

            &__name {
              font-size: 2rem;
              line-height: 2.8rem;

              width: calc(100% - 2rem);

              &--wrapper {
                position: relative;

                button.cta.cta--wishlist {
                  position: absolute;
                  right: 0;
                  top: 0;
                  transform: translateY(1.3rem);
                }
              }
            }

            &__price {
              margin: 0.8rem 0;

              del {
                color: #666;
              }
            }
          }
        }


        .product-selection.container {
          position: unset;
          padding: 0;
          height: auto;

          .product-selection__ctas {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
          }

          .product-selection__size {
            display: none;
          }
        }
      }
    }
  }
}