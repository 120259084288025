.product-zoom {
  .swiper-slide {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 9.1rem;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) -29.66%, rgba(0, 0, 0, 0) 66.95%);
      opacity: 0.5;
      z-index: 1;
      pointer-events: none;
    }
    &::after {
      left: initial;
      right: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) -29.66%, rgba(0, 0, 0, 0) 66.95%);
      opacity: 0.5;
      transform: rotate(-180deg);
    }
  }
}

.product-zoom-modal {
  &__btn.button {
    left: -1.5rem;
  }
  &__btn-right.button {
    left: initial;
    right: -1.5rem;
  }
}
