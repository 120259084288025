.product-selection {
  .selector-size {
    .selector__menu {
      ul {
        grid-template-columns: repeat($selector-size-inpage-cols-mobile, 1fr);
      }
    }
  }
}

button.selector__menu__item.selected:focus {
  border: 0.1rem solid $blue;
}