.swatch {
  display: block;
  background-color: $light_grey; // default per evitare swatch trasparenti quando ci sono valori errati nel catalogo
  width: 4.4rem;
  height: 4.4rem * $product-ratio-num; // mantenere sempre le proporzioni
  object-fit: cover;
  &--black {
    background-color: black;
    // border: $space-line solid white; // no, solo se è sul nero, è controllato dalla classe inverted del padre
  }
  &--blue {
    background-color: rgb(34, 39, 71);
  }
  &--green {
    background-color: rgb(85, 78, 64);
  }
  &--red {
    background-color: rgb(192, 38, 58);
  }
  &--orange {
    background-color: rgb(218, 74, 47);
  }
  &--pink {
    background-color: rgb(206, 169, 162);
  }
  &--purple {
    background-color: rgb(76, 56, 100);
  }
  &--white {
    background-color: rgb(240, 239, 233);
    border: $space-line solid black;
  }
  &--yellow {
    background-color: rgb(255, 183, 0);
  }
  &--grey,
  &--gray {
    background-color: rgb(123, 124, 126);
  }
  &--beige {
    background-color: rgb(202, 192, 174);
  }
  &--miscellaneous {
    background-color: grey;
  }
  &--brown {
    background-color: rgb(81, 58, 56);
  }
  // TODO fix label navy
  &--Navy,
  &--navy {
    background-color: navy;
  }
  &--silver {
    background-color: silver;
  }
  &--fuxia {
    background-color: fuchsia;
  }
  // TODO fix label for multicolor
  &--null,
  &--multicolor,
  &--multicolour,
  &--multicoloured {
    background: linear-gradient(
      180deg,
      rgba(44, 187, 58, 1) 0%,
      rgba(44, 187, 58, 1) 25%,
      rgba(75, 71, 255, 1) 25%,
      rgba(75, 71, 255, 1) 50%,
      rgba(243, 61, 148, 1) 50%,
      rgba(243, 61, 148, 1) 75%,
      rgba(255, 199, 0, 1) 75%
    );
  }
}
// .is-disabled {
//   .swatch {
//     &--black {
//       background-color: rgba(0, 0, 0, 0.2);
//     }
//     &--blue {
//       background-color: rgba(43, 42, 51, 0.2);
//     }
//     &--green {
//       background-color: rgba(85, 78, 64, 0.2);
//     }
//     &--red {
//       background-color: rgba(192, 38, 58, 0.2);
//     }
//     &--orange {
//       background-color: rgba(218, 74, 47, 0.2);
//     }
//     &--pink {
//       background-color: rgba(206, 169, 162, 0.2);
//     }
//     &--purple {
//       background-color: rgba(76, 56, 100, 0.2);
//     }
//     &--white {
//       background-color: rgba(240, 239, 233, 0.2);
//       border-color: rgba(0, 0, 0, 0.2);
//     }
//     &--yellow {
//       background-color: rgba(255, 183, 0, 0.2);
//     }
//     &--grey,
//     &--gray {
//       background-color: rgba(123, 124, 126, 0.2);
//     }
//     &--beige {
//       background-color: rgba(202, 192, 174, 0.2);
//     }
//     &--brown {
//       background-color: rgba(81, 58, 56, 0.2);
//     }
//     // TODO fix label navy
//     &--Navy,
//     &--navy {
//       background-color: rgba(0, 0, 128, 0.2);
//     }
//     &--silver {
//       background-color: rgba(192, 192, 192, 0.2);
//     }
//     &--fuxia {
//       background-color: rgba(255, 0, 255, 0.2);
//     }
//     // TODO fix label for multicolor
//     &--null,
//     &--multicolor,
//     &--multicolour,
//     &--multicoloured {
//       background: linear-gradient(
//         180deg,
//         rgba(44, 187, 58, 0.2) 0%,
//         rgba(44, 187, 58, 0.2) 25%,
//         rgba(75, 71, 255, 0.2) 25%,
//         rgba(75, 71, 255, 0.2) 50%,
//         rgba(243, 61, 148, 0.2) 50%,
//         rgba(243, 61, 148, 0.2) 75%,
//         rgba(255, 199, 0, 0.2) 75%
//       );
//     }
//   }
// }
.inverted {
  .swatch {
    &--black {
      border: $space-line solid white;
    }
    &--white {
      border: none;
    }
  }
}
