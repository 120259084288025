.findinstore {
  .title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 2rem 2.4rem;
    border-bottom: 0.1rem solid $light_grey;

    .close-button {
      position: absolute;
      display: flex;
      align-items: center;
      right: 2.4rem;
      width: 1.6rem;
    }

    .findinstore-come-back-size {
      margin-right: 1.6rem;
    }
  }

  &-size-section {
    padding: 2.4rem;

    .selector-size-expanded .selector__menu {
      padding: 0;
    }

    .find-in-store-button-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2.4rem;
      border-top: 0.1rem solid $light_grey;
      width: 100%;
    }
  }

  .findinstore-map-section {
    height: calc(100% - 7.3rem);
    position: relative;

    .search-wrapper {
      height: 100%;
    }

    .floating-search {
      position: absolute;
      top: 0;
      z-index: 1;
      padding: 2.4rem;
      width: 100%;

      #findinstore-form {
        .icon--search {
          position: absolute;
          padding: 1.7rem 1.6rem;
        }

        .input-text-woosmap {
          padding: 1.6rem 4rem;
          line-height: 1.6rem;
          width: 100%;
          border-radius: 0.4rem;
          border: 0.1rem solid $medium_grey;
        }

        .input-group-button {
          position: absolute;
          top: 0;
          padding: 1.6rem;
          line-height: 1.6rem;
          right: 0;
        }
      }
    }

    .row-pickup-point-container {
      height: 24rem;
    }

    .findinstore-results {
      padding: 2.4rem;
      height: calc(100% - 24rem);
      overflow: auto;

      .result {
        padding: 2.4rem;
        border: 0.1rem solid $medium_grey;
        border-radius: 0.4rem;

        &.result--selected {
          border: 0.1rem solid $blue;
        }

        .text-brand-green {
          color: $green;
        }

        .text-alert {
          color: $orange;
        }

        .store-hours-list {
          height: fit-content;
          max-height: 40rem;

          transition: all 0.4s ease-out;
          overflow: hidden;

          &.hidden {
            padding: 0;
            margin: 0;
            max-height: 0;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0.4rem 0 0 0;

            li {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }

            .active {
              font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
            }
          }
        }

        .store-details {
          .distance {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .details {
          position: relative;

          .store-gotomps-box {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}


.findinstore,
.pickuppoint {

  &-wrapper {
    width: 96vw;
    position: relative;
  }

  .tingle-modal-box {
    max-width: 96rem;
  }

  #findinstore-usecurrentposition {
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__usecurrentposition {
    text-align: center;
  }

  &__input-group {
    position: relative;

    &.has-loader {
      transition: padding 0.3s ease;

      &.loading {}
    }

    [type="submit"] {
      position: relative;
      margin-right: 0;
    }
  }

  // Google's map
  /*
    #map {
        height: 0;c
        max-height: (800);
        display: none;


        .store-hours {
            &-open {
                font-weight: bold;
                color: #67BE7A;
            }

            &-closed {
                font-weight: bold;
                color: #C7383C;
            }
        }
    }
    */

  &-results {

    .result {

      &:not(:first-of-type) {}

      &:not(:last-of-type) {
        border-bottom: 0.1rem solid grey;
      }

      .button {
        margin-bottom: 0;
      }

      i {
        &.fas {}
      }
    }

    &>p {}

    .store-details {
      p {
        display: block;
      }

      .distance {
        display: flex;
        justify-content: space-between;
      }

      .js-toggle-store-hours {
        border-bottom: none;

        .toggle-store-hours-dropdown.flip {
          transform: rotate(180deg);
        }
      }

      .store-hours {
        &-open {
          color: #67BE7A;
        }

        &-closed {
          color: #C7383C;
        }

        &-next-phase {
          font-weight: normal;
        }

      }
    }
  }

  .iphone-disable-zoom {}
}

.margin-distance {}


.fis-select-size-modal,
.prod-select-size-modal {
  z-index: 2051; // above quickview

  .tingle-modal-box {}

  button {
    margin-right: 0;
  }

  ul {
    list-style-type: none;
    width: 100%;
  }

  li {
    display: inline-block;

    button {
      cursor: pointer;
      border-bottom: 0.2rem solid transparent;
      transition: border-color 0.35s ease;

      &[disabled] {
        opacity: 0.4;
      }

      &.selected,
      &:focus,
      &:active,
      &:hover {
        outline: none;

        &:not([disabled]) {
          border-color: $black;
        }
      }
    }
  }
}

.nearby-stores {
  &__icon {
    display: inline-block;
  }

  .result {
    &:not(:first-child) {}

    &:not(:last-child) {
      border-bottom: 0.1rem solid grey;
    }

    .button {
      &.wide {
        min-width: 8rem;
      }
    }
  }
}

.prod-select-size-modal {
  .find-in-store-size-select-btn {
    display: none;
  }

  ul.list-styled-sizes {
    display: flex;
    flex-flow: wrap;
    border-top: 0.1rem solid $disabled_bg_grey;
    border-bottom: 0.1rem solid $disabled_bg_grey;

    li {
      width: 25%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      &.hide {
        display: none !important;
      }

      button {}
    }

    hr {
      width: 100%;
      margin-bottom: 0;
    }
  }
}

.fis-select-size-modal {
  .add-to-cart {
    display: none;
  }
}

#pickupPointMap {
  height: 0;
  background-color: grey;
  display: none;
}

.pickup-input-group-field {
  height: 4rem;
}

.pickup-input-group-button {
  margin-left: 20rem;
  margin-top: 2rem;
}

.js-pup-summary {
  background-color: rgb(244, 244, 244);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.pickup-input-bar {
  border: none;
  border-bottom: 0.1rem solid rgb(128, 128, 128);
  margin-left: 1rem;
  margin-right: 1rem;
}

.label-required-pickup {
  margin-left: 2.5rem;
  color: rgb(47, 79, 79);
  font-size: 1.1rem;
  position: absolute;
  margin-top: 2.5rem;
}

.radius-pickup {
  margin-right: 1rem;
  margin-left: 1rem;
}

.pickupboolean {
  padding-right: 1.14286rem;
}

.pickuppointresults {

  .result {

    &:not(:first-of-type) {}

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid grey;
    }

    .button {
      margin-bottom: 0;
    }

    i {
      &.fas {}
    }
  }

  &>p {}

  .store-details {
    p {
      display: block;
    }

    .distance {
      display: flex;
      justify-content: space-between;
    }
  }
}

#store-locator-root {
  height: calc(100vh - var(--nav-wrapper) - 8rem);
  min-height: 63rem;
}