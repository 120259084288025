.sizevariation-modal {
  &.whishlist-selectsize {
    .product-selection__wrapper {
      .selector-size-expanded {
        .selector__menu {
          padding: 2.4rem 0 0;
        }
      }
    }
  }

  &.modal {
    overflow: visible;
    padding: 2.4rem 1.6rem;
  }

  .modal__close {
    padding: 2.4rem;
  }

  .modal__header,
  .modal-header {
    padding-top: 0;
    font-size: 1.6rem;
    line-height: 1.25;

  }

  .product-selection__wrapper {
    .selector-size-expanded {
      border-top: none;

      .selector__menu {
        padding: 2rem 0 2.6rem;
      }
    }
  }

  .sizevariation-product {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    overflow: hidden;
    padding-bottom: $space-unit * 4;

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }

    .product-image {
      height: auto;
      flex-basis: 9rem;
    }

    >.row {
      flex-grow: 1;
    }

    &__divider {
      @include border('top');
      height: $space-line;
      margin-top: $space-unit * 4;
      margin-bottom: $space-unit * 4;
    }

    &__content {
      position: relative;
      margin-left: $space-unit * 4;
      display: flex;
      flex: 1;
      flex-direction: column;

      //justify-content: space-between;
      >.bag-product__preorderinfo {
        display: none;
      }
    }

    &__out-of-stock {
      position: absolute;
      color: $status_error;
      margin-top: 1rem;
    }

    &__pricename {
      margin-bottom: 1.6rem;
      line-height: 1.8rem;

      h4 {
        margin-bottom: 0.4rem;
      }
    }

    &__name-price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: -0.15rem;

      &__name {
        @extend %primary-s;
        @include del-link;
      }

      &__price {
        @extend %primary-s;
        margin-top: ($space-unit * 2);
        margin-bottom: ($space-unit * 4);

        del {
          color: $red;
        }
      }

      &__message {
        @extend %primary-xs;
        color: $black_secondary;
        margin-top: $space-unit * 2;
        margin-bottom: -($space-unit * 4);
        text-align: right;
      }
    }

    &__details {
      flex-grow: 1;
      color: $dark_grey;
      //margin-top: $space-unit * 5;
      //margin-bottom: $space-unit * 5;

      p {
        @extend %primary-xs;
      }

      div:not(:last-child) {
        margin-bottom: $space-unit * 2;
      }
    }
  }
}