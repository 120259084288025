.floating-thumbnails-wrapper{
  width: fit-content;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  will-change: auto;
  z-index: 1000;
  margin-left: 5.5rem;

  .product-thumbnails-zoom{
    width: 6.6rem;
  }
}

