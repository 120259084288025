.product-selection {
  // padding-top: ($space-unit * 6);

  .product-selection {
    &__wrapper {
      .selector__menu {
        padding-bottom: 0;
      }
    }

    &__heading {

      padding-top: ($space-unit * 4);
      // padding-bottom: $space-unit;
      &.container {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: $white;
        @include z;
      }
      &--placeholder {
        width: 100%;
        display: block;
        position: relative;        
      }

    }

    &__ctas {
      position: sticky;
      z-index: 3;
      bottom: 0;
      padding-bottom: 2rem;
      padding-top: 2.4rem;
      margin: 0;
  
      button {
        height: ($space-unit * 12);
        span {
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }
    }

    &__actions-shipping {
      &.on-pdp {
        @include border('top');
        padding-top: 2.4rem;
      }
    }

    &__size {
      padding-top: 2.4rem;
    }
  }
}

.product-detail__name--wrapper{
  align-items: center;
  max-width: 90%;
  width: 100%;
  position: relative;
  > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
