.product-selection {

  .product-selection__line {
    margin: 0;
    padding: 0;
    border: none;

    &-name_exclusive {
      display: block;
      margin: 0 0 ($space-unit * 3) 0;
    }

    &-name,
    .special-section {
      display: flex;
      width: 100%;
      padding: ($space-unit * 3) 0 ($space-unit * 2) 0;
      @include border('bottom');
    }
  }

  .product-selection__heading {
    padding-bottom: 0;
    padding-top: $space-unit;
  }

  .product-selection__actions-shipping {
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .button+.button {
      margin-left: 0;
      margin-top: ($space-unit * 6);
    }
  }

  .product-selection__ctas {
    display: block;

    .button {
      margin-right: 0;

      &:not(:only-child):not(:last-of-type) {
        margin-bottom: $space-unit * 2;
      }
    }
  }

  &__actions-shipping__actions {
    margin-bottom: ($space-unit * 2);
  }
}

.collection-modal {
  &.modal {
    padding: 0;
  }

  &__title-wrapper {
    height: 6rem;
    border-bottom: 0.1rem solid #e6e6e6;
    margin-bottom: 2.4rem;
    padding: 0 1.6rem;
  }

  &__description {
    padding: 0 1.6rem;
  }
}