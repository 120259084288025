.product-details {
  padding-top: 9.6rem;
}

.product-thumbnails {
  display: block;
  padding: 0;
  height: fit-content;
  position: sticky;
  top: 2rem;

  img {
    margin-bottom: 0.4rem;
  }
}

main .modal-isml.modal-full-width.is-open+.product-thumbnails-zoom {
  display: flex;
}