.switchView{
  .change-grid {
    &-1x {
      order: 3;
    }
    
    &-2x {
      order: 1;
    }
  }
  
  .filter-hr {
    order: 2;
  }
}
