.product-container {
  padding-top: 0;
  min-height: 100vw;

  .product-col{
    min-height: 100vw;
  }
}

#component-product-gallery-with-modal-app{
  min-height: calc(100vw * 0.907 * 4 / 3);
}

.product-sidebar {
  width: 100%;

  #variation-attrs{
    min-height: unset;
  }
}

.product-container.product-detail.product-wrapper {
  margin: 0;
  padding-top: 0;
  top: 0;
}

.container.d-md-none {
  padding: 0;
}

.product-gallery-with-modal .product-container{
 margin: 0;
}

.product-detail__name--wrapper .cta.cta--wishlist {
  position: absolute;
  right: -3.6rem;
  top: 1.2rem;
}

.selector-color-expanded .selector__label {
  padding: 0;
}

.product-detail .prices {
  width: 100%;
}