.sizevariation-modal.modal,
.info-modal {
  padding: 0;
  border-radius: 0.4rem 0.4rem 0 0;
  overflow: unset;

  .modal__body .product-selection__wrapper .selector-size-expanded .selector__menu {
    padding: 0;

    .size-selector-label-wrapper {
      border: 0;
      padding: 0;

      .size-default-wrapper {
        height: 6.4rem;
        padding: 0;
        align-items: center;
        border-bottom: 0.1rem solid $light_grey;

        &::after {
          content: none;
        }
      }

      ul {
        justify-content: flex-start;
        max-height: calc(70vh - 15.5rem);
        height: 70vh;
        padding: 0;

        li {
          height: 4.8rem;
          display: flex;
          align-items: center;
          margin: 0;
          border-radius: 0.4rem;

          &:hover,
          &:focus {
            background-color: $light_grey;
            border-radius: 0.4rem;
          }
        }
      }
    }
  }

  .product-selection__wrapper {
    padding: 0;
    margin: 0 1.2rem;
  }

  .modal__close:before {
    margin: 0.8rem 0;
  }

  .modal__header {
    padding: 2.4rem;
    border-bottom: 0.1rem solid $light_grey;

    .dialog1_label {
      padding: 0;
    }
  }

  .selector-size-expanded .selector__menu .size-selector-label-wrapper .size-default-wrapper {
    display: none;
  }

  .product-selection__ctas {
    height: 8.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.1rem solid $light_grey;

    button {
      width: calc(100% - 4.8rem);
    }
  }
}

.info-modal,
.address-modal {
  .modal__body {
    padding: 2.4rem;
  }
}

.modal.info-modal,
.modal.address-modal {
  height: fit-content;
  margin-top: auto;
}