.product-zoom {
  background: $white;

  .swiper-slide {
    @include animate();
    // opacity: 0 !important;
    pointer-events: none;

    &.swiper-slide-active {
      opacity: 1 !important;
      pointer-events: auto;
    }

    // img {
    //   cursor: grab;
    //   @include animate(0.5, 'opacity');
    //   &.dragging {
    //     cursor: grabbing;
    //   }
    // }
  }

  img {
    position: relative;
    height: 100%;
    // max-height: 100vh;
    width: 100%;
    object-fit: cover;
    max-width: 100%;
    margin: 0 auto;
  }
}

.product-image-button {
  display: block;
  width: 100%;
  height: auto;
  // cursor: url(./../../media/zoom-in.svg), zoom-in;
}

.product-zoom-modal {

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .modal__body,
  .zoom-img-wrapper,
  .product-zoom,
  .gallery-wrapper,
  .gallery-wrapper *:not(img) {
    width: 100%;
    height: 100%;
  }

  &__btn.button {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    z-index: 3;
    left: 1rem;
    width: 8rem;
    height: 8rem;
    padding: 0;
    @include animate();

    &[disabled] {
      opacity: 0;
    }

    &::after {

      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  &__btn-left.button {
    &::after {

      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  &__btn-right.button {
    left: initial;
    right: 1rem;
  }

  &.modal {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    transform: none;

    .modal {

      &__close,
      &__zoom-in,
      &__zoom-out {
        // background-color: #000;
        padding: 0;
        width: 4.4rem;
        height: 4.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__zoom-in,
      &__zoom-out {
        position: absolute;
        top: 0;
        right: 4.5rem;
        z-index: 2;

        &[disabled] {
          opacity: 0.5;
        }

        &::before {
          content: '';
          width: 4.4rem;
          height: 4.4rem;
          display: block;
        }
      }

      &__close {
        width: 5.6rem;
        height: 5.6rem;
        top: 2.4rem;
        right: 2.4rem;
        @include rounded-box;

        &::before {
          @include sprite('close');
        }
      }

      &__zoom-in {
        &::before {
          @include sprite('plus-white');
        }
      }

      &__zoom-out {
        right: 9rem;

        &::before {
          @include sprite('minus-white');
        }
      }
    }
  }

  .gallery-wrapper {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0;
      width: 100%;
      height: 9.8rem;
      @include animate();
    }

    &.scaling {
      &::before {
        opacity: 0.5;
      }
    }
  }
}

.zoom-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}