.sticky-tray {
  position: fixed;
  bottom: 2.4rem;
  padding: 1.6rem;
  left: 50%;

  transform: translateX(-50%);
  width: max-content;
  background-color: $white;
  visibility: hidden;
  @include z('stickybar-m');
  transition-property: opacity;
  opacity: 0;
  max-width: 90vw;

  transition: all 0.4s 0s cubic-bezier(0.25, 1, 0.5, 1);

  &.show {
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
    opacity: 1;
    visibility: visible;
    display: block;
    @include animate(0.4);
  }

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: $space-line;
  //   background-color: $grey_line;
  // }
  &__img-name-price {
    display: grid;
    grid-template-columns: 4.8rem 1fr 4rem;
    grid-column-gap: 1rem;
    padding: 0;
  }

  &__name-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sticky-tray__price {
      .price {
        margin-bottom: 0;
        font-size: 1.4rem;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 4rem;

    button {
      width: 4rem;

      &.button {
        height: 4rem;
        border: 0.1rem solid $medium_grey;
        border-radius: 100%;
        padding: 0;
        background-color: $white;

        span {
          display: none;
        }
      }

      &:nth-child(2) {
        margin-left: $space-unit;
      }
    }
  }

  &__name {
    line-height: 1.29;
    margin-bottom: 0.4rem;
  }

  &__modal.modal {
    width: 100%;
    min-width: initial;
    max-width: initial;
    height: auto;
    right: 0;
    left: 0;
    bottom: 4.8rem;
    top: initial;
    padding-top: 5.5rem;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-delay: 0s;
    opacity: 1;

    &.ReactModal__Content {

      transform: translateY(3.2rem);
    }

    &.ReactModal__Content--after-open {

      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {

      transform: translateY(3.2rem);
    }

    .selector-color-expanded {

      // border-top: initial;
      .selector__label {
        padding-top: 1.5rem;
      }

      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 2.1rem;
      }
    }

    .selector-size-expanded {
      .selector__header {
        padding-top: 1.5rem;
      }

      .selector__menu {
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
      }
    }
  }

  &__overlay {
    &.overlay-modal {
      @include z('stickybar-o-m');
      transition-duration: 0.3s;
      background: $black_secondary;
    }
  }

  .sticky-tray__wrapper .product-image:before {
    padding: 0;
    height: 6.8rem;
  }
}