.product-selection {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  height: 100%;

  &--loading {
    opacity: 0;
  }

  .button.btn-addtobag {
    width: 100%;
  }

  .product-selection__line {
    margin: 0 0 ($space-unit * 2) 0;
    padding: 0 0 ($space-unit * 2) 0;
    @include border('bottom');

    [class^='icon'] {
      margin-right: ($space-unit * 2);
      width: 1.4rem;
      height: 2rem;
    }
  }
  .product-selection__line-name_exclusive {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-selection__line-name {
    text-align: left;
    display: flex;
    align-items: center;
    a {
      font-size: 1.4rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .product-selection__description {
    @extend %primary-xs;
    margin: 0 0 ($space-unit * 7);
  }

  .product-selection__preorder {
    @extend %primary-xs-u;
    margin-bottom: $space-unit * 4;
    text-align: left;
  }

  .product-selection__name-price {
    position: relative;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    padding-top: ($space-unit * 2);
  }

  .product-selection__name-price__name {
    @extend %primary-m;
    text-align: left;
    padding-right: ($space-unit * 8);
    line-height: 1.2;
    padding-bottom: 2.4rem;
  }

  .product-selection__name-price__price {
    @extend %primary-m;
    flex-shrink: 0;
  }

  .product-selection__ctas {
    display: flex;
    margin-bottom: 0.4rem;

    .button {
      margin-right: $space-unit;
      @include text-shorten;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .product-selection__actions-shipping {
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;

    &__contact-us {
      height: 1.6rem;
    }

    .button + .button {
      margin-left: ($space-unit * 6);
    }

    &__shipping {
      @extend %primary-xs;
      text-transform: capitalize;
    }
  }

  .product-selection__modelinfo {
    position: relative;
    margin: $space-unit * 9 0 $space-unit * 1;
    padding-left: $space-unit * 6;
    @include sprite('info');
    background-position: left;
    background-repeat: no-repeat;
    background-size: $space-unit * 4 $space-unit * 4;
    color: $black_secondary;
    min-height: $space-unit * 4;
    display: flex;
    align-items: center;
  }
  &__size {
    display: flex;
    justify-content: space-between;
    
    &__btn {
      margin-left: auto;
    }

    &Guide {
      position: absolute;
      top: -2.4rem;
      transform: translateY(-100%);
      left: 2.4rem;
      background-color: $white;
      font-size: 1.4rem;
      line-height: 2.4rem;
      width: fit-content;
      padding: 1.2rem 3.2rem;
      text-transform: uppercase;
      &__label {
        color: $blue;
      }
    }
  }
}

.collection-modal {
  &.modal {
    min-width: 0;
    width: 54.9rem;
    max-width: 100%;
    padding: 3.2rem 3.6rem;
  }
  &__title-wrapper {
    @extend %primary-m;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    [class^='icon'] {
      width: 1.4rem;
      height: 2rem;
    }
  }

  &__title {
    margin-left: ($space-unit * 2);
  }
  &__btn-container {
    text-align: center;
    margin-top: 2.7rem;
  }
  .button {
    display: inline-flex;
    width: auto;
    min-width: 22.6rem;
  }
}
